<template>
  <div class="content_box">
    <!-- 顶部图片 -->
    <div class="head_img_box">
      <el-image :src="headImg">
        <div slot="placeholder" class="image-slot">
          <img :src="loadImg" class="loadImg" alt />
        </div>
      </el-image>
      <!-- 使用方法 -->
      <div class="usage_box">
        <div
          class="title"
        >岡山エリアで人気のチケットです。岡山の魅力的な観光スポットと人気施設が対象し、有効期間で対象施設の中からお好きな３つ施設を選んで楽しめます。また、電子チケットQRコードを提示すると簡単に入場ができます。</div>
        <div class="syff">
          <div class="syff_left">
            <p></p>
            <p></p>
          </div>
          <div class="txt">使用方法</div>
          <div class="syff_right">
            <p></p>
            <p></p>
          </div>
        </div>
        <div class="neiron">
          ●各施設のご利用は1回のみです。同施設を2回ご利用できません。
          <br />●パスの使用期間は購入日から
          <span>90日</span>以内です。
          <br />EX：
          <span>7/1</span>ご購入の場合、
          <span>7/1~9/28</span>使用可能です。
          <br />●パスの有効期間：1つ目の施設をご入場から
          <span>7日間</span>以内です。
          <br />EX：
          <span>7/1</span>から利用開始の場合、有効期間は
          <span>7/7</span>までです。
          <br />●利用可能施設数：参画施設の中から、任意の<span>3施設</span>
          <br />●注意：同オーダーに複枚数をご購入の場合、中の一枚を利用すると、全て同じ
          <span>7日間</span>有効になります。
          <br />●パスの有効期間7日間は購入日から
          <span>90日間</span>以内に限ります。
          <br />EX：
          <span>7/1~9/28</span>使用可能のパスを
          <span>9/27</span>から利用開始された場合、
          有効期間は
          <span>9/27~9/28</span>です。
          <br />●未使用分を払い戻すことはできません。
          <br />●各施設の営業時間・定休日・利用に関する注意事項は下記リンク、
          または施設公式サイトでご確認ください。
        </div>
      </div>
      <!-- 景点介绍 -->
      <div class="main_box" v-for="(item,i) in dataList" :key="i">
        <!-- 图片 -->
        <div class="img_box">
          <el-image :src="item.img" lazy>
            <div slot="placeholder" class="image-slot">
              <img :src="loadImg" class="loadImg" alt />
            </div>
          </el-image>
        </div>

        <!-- 文字 -->
        <div class="text_box">
          <div class="title" v-for="(title,i) in item.title" :key="i" v-html="title"></div>
          <hr />
          <div class="message_box">
            <div class="message_item" v-for="msg in item.message" :key="msg.msgId">
              <div style="margin-bottom:5px">{{msg.title}}</div>
              <div v-if="msg.title!=='注意事項'">
                <div v-for="contentItem in msg.content" :key="contentItem.cid">
                  <span v-for="(cItem,i) in contentItem.text" :key="i">
                    <span v-show="msg.title==='引き換え場所' && !contentItem.noColor">
                      {{cItem}}
                      <br />
                    </span>
                    <a
                      :style="[{'borderBottom': !contentItem.noColor ? '1px solid' : ''},{color: !contentItem.noColor ? '#219DCC' : ''}]"
                      target="_blank"
                      :href="cItem"
                      v-show="msg.title==='公式サイト' && !contentItem.noColor"
                    >
                      {{cItem}}
                      <br />
                    </a>
                    <p v-show="contentItem.noColor" v-html="cItem"></p>
                  </span>
                </div>
              </div>
              <!-- 注意事項特殊处理 -->
              <!-- :style判断是否要数字前缀 -->
              <div :style="{marginLeft:msg.isDecimal ? '16.5px' : '0px'}" v-else>
                <ul :style="{listStyleType:msg.isDecimal ? 'decimal' : 'none'}">
                  <li v-for="contentItem in msg.content" :key="contentItem.cid">
                    <span v-html="cItem" v-for="(cItem,i) in contentItem.text" :key="i"></span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 景点介绍 end -->
    </div>
  </div>
</template>
  <script>
export default {
  data() {
    return {
      headImg: require("@/assets/images/hfokayama/ja/havefun_title-ja.jpg"),
      loadImg: require("@/assets/images/hfkansai/loading.png"),
      dataList: [
        {
          id: 10,
          img: require('@/assets/images/hfokayama/10_EN.png'),
          title: ['岡山城天守閣　入場券＋天守閣1 階「烏城カフェ」季節のパフェ'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: [
                '〒700-0823岡山市北区丸の内2-3-1',
                '入場券:',
                '電子チケットを岡山城天守閣の入場券売り場のスタッフにご提示ください。入場券をお渡しします。',
                '「烏城カフェ」季節のパフェ:',
                '電子チケットと入場券の両方を、天守閣1階「烏城カフェ」のスタッフにご提示ください。入場券と引き換えに「季節のパフェ」をご提供いたします。',
                ],noColor:true }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['岡山城:'],noColor:true },
                { cid: 2, text: ['https://okayama-castle.jp'] },
                { cid: 3, text: ['岡山城　天守閣1階「烏城カフェ」:'],noColor:true },
                { cid: 4, text: ['https://okayama-castle.jp/gather-shop/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['営業時間は事前に公式サイトをご確認ください。','<br>※休館日：12/29～12/31'] },
                { cid: 2, text: ['満席になって利用できない可能があります。予めご了承ください。'] },
                { cid: 3, text: ['画像はイメージです。実際の商品とはデザイン・仕様が一部異なる場合がございます。'] },
                ]
            }
          ]
        },
        {
          id: 1,
          img: require('@/assets/images/hfokayama/01_EN.png'),
          title: ['岡山県夢二郷土美術館 本館 入館券'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: [
                '〒703-8256 岡山県岡山市中区浜2丁目1-32'],noColor:true }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://yumeji-art-museum.com/korean/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['営業時間は事前に公式サイトをご確認ください。'] },
                { cid: 2, text: ['本館のみ入場できます。(少年山荘の入場は別途料金が必要です)'] },
                ]
            }
          ]
        },
        // 
        {
          id: 2,
          img: require('@/assets/images/hfokayama/02_EN.png'),
          title: ["ホテルグランヴィア岡山「ルミエール」1,000円クーポン"],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: [
                '〒700-8515 岡山市北区駅元町1番5'],noColor:true }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://www.granvia-oka.co.jp/restaurant/lumiere/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1000円クーポンをご堪能いただけます。'] },
                { cid: 2, text: ['商品提供時間：9：00～21：00（ラストオーダー20：30）但し、パフェ提供は12：00～21：00（ラストオーダー20：30）'] },
                { cid: 3, text: ['季節によりフルーツパフェのフルーツが異なります。商品内容の写真はイメージです。'] },
                { cid: 4, text: ['クーポン金額以上の差額分はお客様負担になります。'] },
                { cid: 5, text: ['会計後のキャンセル不可'] },
                { cid: 6, text: ['満席になって利用できない可能があります。予めご了承ください。'] },
                ]
            }
          ]
        },
        // 
        {
          id: 3,
          img: require('@/assets/images/hfokayama/03_EN.png'),
          title: ['岡山県おかでんミュージアム入館券'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: [
                '〒703-8281岡山県岡山市中区東山2丁目3-33'],noColor:true }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://okayama-kido.co.jp/okadenmuseum/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['営業時間は事前に公式サイトをご確認ください。'] },
                ]
            }
          ]
        },
        // 
        {
          id: 4,
          img: require('@/assets/images/hfokayama/04_EN.png'),
          title: ['café Antena 1,000円クーポン券'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: [
                '〒700-0812　岡山市北区出石町1-8-23'],noColor:true }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://okayama-parfait.com/parfait/264.html'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1000円クーポンをご堪能いただけます。'] },
                { cid: 2, text: ['営業時間は事前に公式サイトをご確認ください。'] },
                { cid: 3, text: ['料金差額分の場合、現場でお支払い必要があります。'] },
                { cid: 4, text: ['満席になって利用できない可能があります。予めご了承ください。'] },
                ]
            }
          ]
        },
        // 
        {
          id: 5,
          img: require('@/assets/images/hfokayama/05_EN.png'),
          title: ['岡山県倉敷美観地区まち歩きクーポン'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: [
                '倉敷駅前観光案內所','岡山県倉敷市阿知1-7-2'],noColor:true }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://okayama.visit-town.com/visittour/cp_kurashiki-machi-2023/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['内容により売り切れの際は代替品はございません。他店舗をご利用ください。また、店舗には臨時休業もございます。'] },
                { cid: 2, text: ['当クーポンと、他の割引内容との併用・重複のご利用はできません。'] },
                { cid: 3, text: ['お客様の未使用クーポン分は、返金できませんのであらかじめご了承ください。'] },
                { cid: 4, text: ['当クーポン本券の盗難・紛失などに関しては、一切その責任を負いません。'] },
                { cid: 5, text: ['有効期間は2023年4月1日～2024年3月31日となります。'] },
                { cid: 6, text: ['予告なく利用店舗の増減や提供内容が変更になる場合があります。'] },
                { cid: 7, text: ['1冊（10枚綴り）につき1名様のご利用となります。'] },
                { cid: 8, text: ['利用店舗により必要枚数が異なります。入館・体験で10枚（1冊）必要な施設もあります。他の施設もご利用になられます場合は2冊以上お買い上げください。'] },
                { cid: 9, text: ['お客様ご自身で注意事項等よくご確認の上、施設にお越しください。'] },
                ]
            }
          ]
        },
        // 
        {
          id: 6,
          img: require('@/assets/images/hfokayama/06_EN.png'),
          title: ['ベティズストア&アウトレットで使える1,500円クーポン'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: [
                '〒711-0906 岡山県倉敷市児島下の町５丁目２−７０ ベティスミス'],noColor:true }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://betty.co.jp/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1500円クーポンをご堪能いただけます。'] },
                { cid: 2, text: ['営業時間は事前に公式サイトをご確認ください。'] },
                { cid: 3, text: ['料金差額分の場合、現場でお支払い必要があります。'] }
                ]
            }
          ]
        },
        // 
        {
          id: 7,
          img: require('@/assets/images/hfokayama/07_EN.jpg'),
          title: ['ジーンズのまち児島 バス1日乗車券'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: [
                '【児島営業所】〒711-0913　倉敷市児島味野3-2-10'],noColor:true }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['http://shimoden.net/rosen/kikaku/jeans.html#oneday'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1日乗車券に引換後の払い戻しは出来ません。紛失、盗難時にも再発行は出来ません。'] },
                { cid: 2, text: ['"天災地震事故などでバスが遅延したり、観光施設が閉館した場合も払い戻しできません。'] },
                { cid: 3, text: ['バスを乗り遅れてタクシー等を利用した場合も払い戻しできません。'] },
                { cid: 4, text: ['対象路線は、ジーンズバス、「ふれあい号」、「とこはい号」、「王子ヶ岳線（王子ヶ岳登山口停留所まで）」です。'] },
                { cid: 5, text: ['ご利用には必ず児島営業所にて1日乗車券の引き換えが必要です。※バス車内での引き換えは出来ません。'] },

                ]
            }
          ]
        },
        // 
        {
          id: 8,
          img: require('@/assets/images/hfokayama/08_EN.jpg'),
          title: ['WASHU BLUE RESORT 風籠　温泉日帰り入浴券'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: [
                '〒711-0926 岡山県倉敷市下津井吹上303-17'],noColor:true }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://kasago.jp/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['日帰り入浴の休止日がございます。詳しくはHPをご覧ください。'] },
                ]
            }
          ]
        },
        // 
        {
          id: 9,
          img: require('@/assets/images/hfokayama/09_EN.png'),
          title: ['JR岡山駅前の家電量販店「ビックカメラ岡山駅前店」商品券1000円分'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: [
                '〒700-0023 岡山県岡山市北区駅前町1-1-1　1階総合案内カウンター'],noColor:true }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://www.biccamera.com/bc/c/super/okaimono/oshiharai/coupon/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['現金とお取り替えいたしません。'] },
                { cid: 2, text: ['盗難紛失又は減失等に対しては当社はその責を負いません。'] },
                { cid: 3, text: ['他の商品券、はがき、印紙、タバコ、その他当社が指定した商品等との引き換えはいたしません。'] },
                { cid: 4, text: ['各店舗の営業時間は事前に各公式サイトをご確認ください。'] },
                { cid: 5, text: ['料金差額分の場合、現場でお支払い必要があります。'] },
       
                ]
            }
          ]
        },
        // 
      ]
    };
  },
  created () {
    // this.$router.push('/hfPage?id=HFOkayama&l=jp')
    window.open('https://www.travelcontentsapp.com/have-fun/have-fun-in-okayama-1-week-free-pass/jp', '_self')
  }
};
</script>
  <style lang="scss" scoped>
.content_box {
  font-family: "Genseki";
  position: relative;
  width: 100%;
  height: 100%;
  // 使用方法
  .usage_box {
    background-color: #fffba0;
    padding: 18px 20px;
    margin: 20px 15px;
    color: #1a1311;
    font-weight: 700;
    margin-top: 0;
    .title {
      font-size: 13px;
      line-height: 19px;
    }
    .syff {
      display: flex;
      justify-content: space-between;
      margin: 18px 0;
      .txt {
        font-size: 21px;
        margin: 0 8.5px;
      }

      .syff_left,
      .syff_right {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p {
          display: inline-block;
          height: 1px;
          width: 100%;
          background-color: #707070;
          margin-bottom: 2px;
        }
      }
    }
    .neiron {
      line-height: 21px;
      font-size: 12px;
      span {
        color: #fa6460;
      }
    }
  }
  .main_box {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 26px;
    .img_box {
      width: 100%;
      box-shadow: 6px 0px 6px rgba(0, 0, 0, 0.16);
    }

    .text_box {
      position: relative;
      margin-top: 22px;
      padding: 0 10px;
      // width: 90%;
      // padding-left: 24px;
      // padding-right: 17px;
      .title {
        font-size: 17px;
        font-weight: bolder;
        color: #876b57;
        margin: 5px 0;
        /deep/span {
          color: #ff0000;
        }
      }
      .message_box {
        font-size: 13px;
        line-height: 17px;
        font-weight: bold;
        margin-top: 5px;
        .message_item:not(:first-child) {
          margin-top: 22px;
        }
      }
    }
  }
}
.loadImg {
  width: 100%;
  height: auto;
}
hr {
  border: none;
  height: 1px;
  background-color: #707070;
}
a:link {
  color: #000;
}
a:visited {
  color: #000;
}
a:hover {
  color: #000;
  border-bottom: 1px solid;
}
a:active {
  color: #000;
}
</style>